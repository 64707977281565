@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --theme-color: #1976d2;
  --text-color: #ddd;
  --theme-bg: #000;
  --theme-text-color: #fff;
}

* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  word-spacing: 2px;
  letter-spacing: 0.1rem;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--theme-bg);
  scroll-behavior: smooth;
}

.MainApp {
  background-color: var(--theme-bg);
  color: var(--text-color);
  min-height: 100vh;
}

.theme-text {
  color: var(--theme-color);
}

.article-img {
  cursor: zoom-in;
  filter: brightness(0.75);
  transition: filter 100ms ease;
}

.article-img:hover {
  filter: brightness(1);
}

.contact-bg-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: -1; */
  filter: brightness(0.4) contrast(1.1);
}

.contactus-box,
.aboutus-box {
  color: var(--text-color);
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #333;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}