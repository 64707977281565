.projects-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: filter 200ms ease;
}

.projects-img:hover {
    filter: brightness(0.5);
}