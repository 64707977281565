.abt-carousel-img {
    object-fit: cover;
    /* padding: 15px;
    background: black; */
    filter: brightness(0.95);
    height: 70vh;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .abt-carousel-img {
        height: 55vh;
    }
}